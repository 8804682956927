<template>
	<div class="line">
		
		<div>
			<van>
				<van-list style="padding:15px 15px 50px 15px;height: 100%;" v-if="goodsList.length != 0">
					<div v-for="(item,index) in goodsList" :key="index" class="goods-item">
						<van-swipe-cell>
							<div style="width: 100%;" class="list">
								<div>
									<p style="font-size: 18px;margin: 0;">{{item.name}}</p>
									<p style="font-size: 14px;color: #777;margin: 0;">{{item.tip}}</p>
								</div>
								<div>
									<p style="margin: 0;">当前叫号:<span style="font-size: 18px;color: #ee0a24;margin: 0 3px;">{{item.current_num}}</span></p>
									<p style="margin: 0;">排队<span style="font-size: 18px;color: #ee0a24;margin: 0 3px;">{{item.line_num}}</span>
									人</p>
								</div>
								<button class="pickNum" @click="callName(item.id)">叫号</button>
							</div>
						</van-swipe-cell>
					</div>
				</van-list>
				<van-empty description="暂无排队" v-else />
			</van>
		</div>
		<div class="top">
			<button class="primary" @click="setting">设置</button>
			<div style="display: flex;align-items: center;color: #646566;margin-left: 15px;">
				<p style="margin: 0; margin-right: 10px;">排队开关</p>
				<van-switch v-model="formdata.is_line" active-color="#ee0a24" size="20" :active-value="1" :inactive-value="0"
					@change="statusVal(value)" />
			</div>
		</div>
	</div>
</template>

<script>
	import {
		lineOverview,
		call,
		info,
		changest
	} from '@/api/line'
	import {
		Toast,
		Dialog
	} from "vant";
	export default {
		data() {
			return {
				goodsList: [], // 请求数据
				name: '',
				tip: '',
				current_num: '',
				line_num: '',
				formdata: {
					is_line: 0
				}
			}
		},
		methods: {
			lineOverview() {
				lineOverview().then((res) => {
					// console.log(res)
					this.goodsList = res.data
				})
			},
			callName(id) {
				let params = {
					lid: id
				}
				call(params).then((res) => {
					// console.log(res)
					Dialog.alert({
						message: '当前叫号' + res.data.num,
					}).then(() => {
						this.lineOverview()
					});
				})
			},
			setting() {
				this.$router.push({
					path: '/lineList'
				})
			},
			infos() {
				info().then((res) => {
					// console.log(res)
					// console.log(res.data.info.is_line)
					if (res.data.info.is_line == 1) {
						this.lineOverview()
						this.formdata.is_line = res.data.info.is_line
					} else {
						Dialog.confirm({
								title: '排队',
								message: '是否开启排队功能',
							})
							.then(() => {
								let params = {
									field: 'is_line',
									status: 1
								}
								changest(params).then((res) => {
									// console.log(res)
									Toast({
										message: res.msg,
									});
									this.lineOverview()
									location.reload();
								})
							})
							.catch(() => {
								this.$router.go(-1)
							});
					}
				})
			},
			statusVal(value) {
				// console.log(this.formdata.is_line)
				let params = {
					field: 'is_line',
					status: this.formdata.is_line
				}
				changest(params).then((res) => {
					// console.log(res)
					Toast({
						message: res.msg,
					});
					this.lineOverview()
					location.reload();
				})
				// this.formdata.status
			},
		},
		mounted() {
			this.infos()
		}
	}
</script>

<style lang="less">
	.line {
		min-height: calc(100vh);
		position: relative;
		.top{
			display: flex;
			position: absolute;
			bottom: 10px;
			left: calc(50% - 100px);
			.primary{
				width: 80px;
				height: 40px;
				color: #fff;
				background-color: #ee0a24;
				border: 1px solid #ee0a24;
				border-radius: 20px;
			}
		}
		.goods-item {
			background-color: #FFFFFF;
			// height: 88px;
			border-radius: 5px;
			margin-bottom: 10px;
			font-size: 14px;
			padding: 15px 10px;

			.list {
				width: 100%;
				display: flex;
				align-items: center;
				justify-content: space-around;
				.pickNum{
					width: 80px;
					height: 40px;
					color: #fff;
					background-color: #ee0a24;
					border: 1px solid #ee0a24;
					border-radius: 20px;
				}
				div {
					margin: 0 10px;
				}
			}
		}

	}
</style>
